import logo1 from '../../images/TC1.jpg';
import logo2 from '../../images/TC2.jpg';
const LadoIzq = () => {
    return ( <>
        <div className="">
            <img src={logo1} className="hidden lg:block" alt="" />
            <img src={logo2} className="hidden lg:block" alt="" />
        </div>
    </> );
}
 
export default LadoIzq;