
import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router';
import DatosCliente from './components/paginas/DatosCliente';
import DatosTicket from './components/paginas/DatosTicket';
import LadoIzq from './components/paginas/LadoIzq';
import LadoDer from './components/paginas/LadoDer';

function App() {
  return (
    <div className="p-12 grid grid-cols-1 lg:grid-cols-3 gap-x-3">
    <LadoIzq/>
    <Routes>
      <Route path="" element={<DatosTicket/>}/>
      <Route path="/DatosCliente/" element={<DatosCliente/>}/>
    </Routes>
    <LadoDer/>
    </div>
  );
}
export default App;
